.zps-content-product .zps-main-panel .zps-main-topbar {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 48px;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(235, 235, 235);
    display: flex;
}
.zps-main-topbar>.zps-main-titlearea {
    display: flex;
    align-items: center;
    height: 100%;
    width: calc(100% - 450px);
    padding-left:  38px;;
}
.zps-main-titlearea>.zps-title-content {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    height: 100%;
}
.zps-title-content>.topbar-project-name {
    cursor: default;
    font-family: 'PuviLtBold',Roboto,sans-serif;
    padding-right: 20px;
    border-right: rgb(235, 235, 235) solid 1px;
    display: flex;
    align-items: center;
}
.zps-title-content .topbar-project-name, .zps-title-content>.project-uppertabs-parent {
    white-space: nowrap;
    display: inline-flex;
    vertical-align: top;
    position: relative;
    font-size: 1rem;
    height: 100%;
    margin-right: 15px;
    padding: 0 8px;
    align-items: center;
}
.zps-title-content>.topbar-project-name span {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    z-index: 1;
    line-height: 1.5;
}
.zps-title-content>.project-uppertabs-parent.active {
    color: rgb(255, 100, 0);
}
.zps-title-content>.project-uppertabs-parent {
    max-width: 150px;
}
.zps-title-content .project-uppertabs-parent:before {
    content: "";
    width: 100%;
    height: 3px;
    position: absolute;
    background: rgb(255, 100, 0);
    z-index: -1;
    bottom: 1px;
    left: 0;
    right: 0;
    transition: .2s all linear;
    opacity: 0;
    margin: auto;
}
.zps-title-content .project-uppertabs-parent.active {
    color: rgb(255, 100, 0);
}
.zps-title-content .project-uppertabs-parent .project-uppertabs-linkwrap {
    display: inline-block;
    vertical-align: top;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;
    max-width: 100%;
    white-space: nowrap;
    line-height: 1.5;
    cursor: pointer;
}
.zps-title-content .project-uppertabs-parent::after {
    content: "";
    width: 90%;
    height: 50%;
    padding: 5px 10px;
    position: absolute;
    background: rgb(255, 247, 243);
    z-index: -1;
    border-radius: 4px;
    top: 6px;
    transform: scale(0.7);
    left: -5px;
    transition: .2s all linear;
    opacity: 0;
}
.zps-title-content>.project-uppertabs-parent:before {
    content: "";
    width: 100%;
    height: 3px;
    position: absolute;
    background: rgb(255, 100, 0);
    z-index: -1;
    bottom: 1px;
    left: 0;
    right: 0;
    transition: .2s all linear;
    opacity: 0;
    margin: auto;
}
.zps-title-content .project-uppertabs-parent .project-uppertabs-linkwrap {
    display: inline-block;
    vertical-align: top;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;
    max-width: 100%;
    white-space: nowrap;
    line-height: 1.5;
    cursor: pointer;
    font-size: 15px;
}

.Projet-link{
    text-decoration-line: none;
    color: black;
}

.zps-title-content .project-uppertabs-parent .project-uppertabs-linkwrap :active{
    text-decoration-line: none;
    color: #FF6400;
}
.zps-title-content .project-uppertabs-parent .project-uppertabs-linkwrap :focus{
    text-decoration-line: none;
    color: #FF6400;
}
.zps-title-content .project-uppertabs-parent::after {
    content: "";
    width: 90%;
    height: 50%;
    padding: 5px 10px;
    position: absolute;
    background: rgb(255, 247, 243);
    z-index: -1;
    border-radius: 4px;
    top: 6px;
    transform: scale(0.7);
    left: -5px;
    transition: .2s all linear;
    opacity: 0;
}

.zps-top-action {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}

.zps-main{
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 24px;
}

.icon{white-space: nowrap;
display: inline-flex;
vertical-align: top;
position: relative;
font-size: 1rem;
height: 100%;
margin-right: 15px;
padding: 0 8px;
align-items: center;

}

#offcanvas{color:#FF6400}


.zps-content-product .zps-menu-panel .zps-panel-logo {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    background-color: rgba(var(--zpMenuPanel-overlay),0.25);
    border-bottom: 1px solid rgba(var(--zpOnMenuPanel),0.10);
    border-top: 1px solid rgba(var(--zpOnMenuPanel),0.10);
}

.img {
    margin-left: 20px;
    display: inline-block;
}




ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding: 10px 5px;
  }
  
  .zps-items-list li a {
    display: block;
    width: 60px;
    text-decoration-line: none;
    /* color: #fff;*/
    padding: 2px;
   color: black;
  }

  .WorkOverview{
padding-left: 33px;
  font-size: 17px;
  color: black;
}
  
.zps-list-name  a {
    
    width: 60px;
    text-decoration-line: none;
    /* color: #fff;*/
    padding: 2px;
   color: black;
  }

  .global-allProjects a{
    display: block;
    padding: 2px;
    color: gray;
    text-decoration-line: none;
  }